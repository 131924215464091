export function rCalculator(xValues: Array<number>, yValues: Array<number>): { r: number; rsquared: number, n: number } {
    const x = [...xValues];
    const y = [...yValues];

    while (true) {
        const xIdx = x.findIndex(item => item === undefined || item == null);
        const yIdx = y.findIndex(item => item === undefined || item == null);


        if (xIdx < 0 && yIdx < 0) {
            break;
        }

        if (xIdx >= 0) {
            x.splice(xIdx, 1);
            y.splice(xIdx, 1);
        }

        if (yIdx >= 0 && yIdx !== xIdx) {
            x.splice(yIdx, 1);
            y.splice(yIdx, 1);
        }
    }

    if (x == null || y == null || x.length === 0 || x.length !== y.length) {
        console.log(`Number of X Values does not match number of Y Values. [${x.length}, ${y.length}] `);
        return {r: NaN, rsquared: NaN, n: NaN};
    }

    let sumX = 0;
    let sumY = 0;
    let ssX = 0;
    let ssY = 0;
    let prodOfDiff = 0;

    x.forEach(v => sumX += v);
    y.forEach(v => sumY += v);

    const meanX = sumX / x.length;
    const meanY = sumY / y.length;

    x.forEach(v => ssX += Math.pow(v - meanX, 2));
    y.forEach(v => ssY += Math.pow(v - meanY, 2));

    for (let idx = 0; idx < x.length; idx++) {
        prodOfDiff += (x[idx] - meanX) * (y[idx] - meanY);
    }

    const sqrt = Math.sqrt(ssX * ssY);
    if (sqrt === 0) {
        return {r: 0, rsquared: 0, n: x.length};
    }

    const r = prodOfDiff / sqrt;

    return {r: Math.round(r * 1000) / 1000, rsquared: Math.round(Math.pow(r, 2) * 1000) / 1000, n: x.length};
}
