import {Injectable} from '@angular/core';
import {TiberEnvironmentProvider} from "../environment";

@Injectable({providedIn: 'root'})
export class RedirectPostService {

    constructor(private environment: TiberEnvironmentProvider) {
    }

    post(obj: {}, url: string) {
        let mappedUrl = url;
        const host = this.environment.config.apiHost;

        if ((host || '').length > 0) {
            mappedUrl = `${host}${mappedUrl}`;
        }

        const mapForm = document.createElement('form');
        mapForm.taraget = '_blank';
        mapForm.method = 'POST'; // or "post" if appropriate
        mapForm.action = mappedUrl;
        Object.keys(obj).forEach(param => {
            const mapInput = document.createElement('input');
            mapInput.type = 'hidden';
            mapInput.name = param;
            mapInput.setAttribute('value', obj[param]);
            mapForm.appendChild(mapInput);
        });
        document.body.appendChild(mapForm);
        mapForm.submit();
    }

    handleExceptionRedirect(message: string) {
        this.post({
            message
        }, '/lti/exception');
    }

}
