<div class="app-mainComponent-main" *ngIf="menu$ | async as menu else loading">
    <div class="app-mainComponent-navBar">
        <div class="app-toggle-sidebar" (click)="showSidebar()">
            <img cdnImage *ngIf="!pageService.sideBarLocked && pageService.lockable" src="/assets/more.png" title="Show Side Menu" alt="Show Side Menu icon"/>
        </div>
        <ul #navBar>
            <li *ngFor="let item of menu.menuItems | visible; let i = index"
                [ngClass]="{'app-navBar-lockedMenu': i === 0}"
                [routerLink]="item.routeMap"
                routerLinkActive="linkActive">{{ item.name }}
                <div class="app-mainComponent-navBar-subMenu-container"
                     *ngIf="(item.menuItems || []).length > 0">
                    <ul class="app-mainComponent-navBar-subMenu">
                        <li *ngFor="let subItem of item.menuItems | visible "
                            (click)="navigate(subItem.routeMap, $event)">
                            {{ subItem.name }}
                        </li>
                    </ul>
                </div>
            </li>
            <!-- User Menu - everyone gets this -->
            <li><img class="profileImage" #profileImage/>{{ displayName }}
                <div class="app-mainComponent-navBar-subMenu-container app-mainComponent-navBar-subMenu-containerRight">
                    <ul class="app-mainComponent-navBar-subMenu app-mainComponent-navBar-subMenu-right">
                        <li (click)="showWhoAmIDetails()">Who am I</li>
                        <li (click)="logout()">Sign Out</li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
    <div class="app-mainComponent-body">
        <router-outlet></router-outlet>
    </div>
</div>
<app-details #appDetailsComponent></app-details>
<ng-template #loading>
    <div class="centerBrowser">
        <app-loading-spinner></app-loading-spinner>
    </div>
</ng-template>
