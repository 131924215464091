<div class="app-main">
    <div class="app-main-head">
        <div class="logo">
            <img cdnImage src="/assets/TiberLogoImage_White.png" alt="Tiber Health graphic logo in White">
        </div>
        <div>
            Tiber Health Management Portal
        </div>
        <div class="app-main-pageTitle">
            {{ pageTitle }}
        </div>
    </div>
    <div class="app-main-body">
        <router-outlet></router-outlet>
    </div>
    <div class="app-main-foot">
        <div class="versionInfo">Client: {{ version }} Server: {{ systemHealth }}</div>
        <div class="environmentInfo">{{ databaseName }} {{ environment }}</div>
        <div>&copy;{{ copyrightYear }} - Tiber Health, Inc.</div>
    </div>
</div>
