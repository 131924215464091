import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class WindowRefService {
    get nativeWindow(): Window {
        return window;
    }

    get baseFontSize(): number {
        return parseFloat(this.nativeWindow.getComputedStyle(this.nativeWindow.document.body)['font-size']);
    }
}
