/**
 * Converts a z-score to a percentage
 * @param zScore - The z-score to convert
 */
export function z2Percent(zScore: number): number {
    // Find the cumulative probability associated with the z-score
    const cumulativeProbability = 0.5 * (1 + erf(zScore / Math.sqrt(2)));

    // Round the percentage to two decimal places
    return +cumulativeProbability.toFixed(4);
}

// Define the error function (erf) used in the zScoreToPercentage function
export function erf(x) {
    const a1 = 0.254829592;
    const a2 = -0.284496736;
    const a3 = 1.421413741;
    const a4 = -1.453152027;
    const a5 = 1.061405429;
    const p = 0.3275911;

    const sign = x < 0 ? -1 : 1;
    const absX = Math.abs(x);

    const t = 1 / (1 + p * absX);
    const y =
        1 -
        (((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-absX * absX));

    return sign * y;
}
