import {Input, Directive, ElementRef, Renderer2} from '@angular/core';
import {deployUrl} from '../../_helpers/deployUrl';
import {ApplicationConfiguration} from '../../_models/application-configuration';

@Directive({
    selector: '[cdnImage]'
})
export class CdnImageDirective {
    @Input('cdnBackgroundImage') set backgroundImageSrc(val: string) {
        const url = deployUrl(val, this.appConfig);
        this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${url}`);
    }


    @Input('cdnImage') set imgSrc(val: string) {
        if (val == null || val.length === 0) {
            return;
        }
        this.fixUrl(val);
    }


    constructor(private el: ElementRef, public renderer: Renderer2, private appConfig: ApplicationConfiguration) {
        if (el.nativeElement.src != null) {
            this.fixUrl(el.nativeElement.src);
        }
    }

    private fixUrl(url: string) {
        this.el.nativeElement.src = deployUrl(url.replace(/^.*\/\/[^\/]+/, ''), this.appConfig);
    }
}
