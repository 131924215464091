import {lastValueFrom, Observable} from 'rxjs';
import {delay} from 'rxjs/operators';

export function delayPromise(ms = 1): Promise<any> {
    return lastValueFrom(delayObservable(ms));
}

export function delayObservable(ms = 1): Observable<null> {
    return new Observable<null>(observer => {
        observer.next(null);
        observer.complete();
    })
        .pipe(delay(ms));
}
