import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, tap} from 'rxjs';
import {map} from 'rxjs/operators';

import {User} from '../_models';
import {AppParameters} from '../_models/app.parameters';
import {Router} from '@angular/router';
import {MenuItem, UserMenu} from '../_models/UserMenu';
import {ApiHelper, ApiResult} from '../_helpers';
import {CanvasUserIdResponse} from '../_models/canvas-user-id-response';

@Injectable()
export class AuthenticationService {

    private currentUserSubject = new BehaviorSubject<User>(null);
    private userMenuSubject = new BehaviorSubject<UserMenu>(null);

    private phsuToolsSubject = new BehaviorSubject<string[]>([]);
    currentUser: Observable<User>;

    constructor(
        protected readonly http: HttpClient,
        protected readonly appParameter: AppParameters,
        protected readonly router: Router,
    ) {
        // @ts-ignore
        const user = JSON.parse(sessionStorage.getItem('currentUser'));
        this.setCurrentUser(user);
    }

    get isAuthenticated(): boolean {
        return this.currentUserValue != null;
    }

    get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    get userIsInstructor(): boolean {
        return this.currentUserValue.isLti && (this.currentUserValue.isInstructor || this.currentUserValue.isCourseAdmin);
    }

    get userIsCourseAdmin(): boolean {
        return this.currentUserValue.isLti && this.currentUserValue.isCourseAdmin;
    }

    get isLti(): boolean {
        return this.currentUserValue?.isLti || false;
    }

    login(username: string, password: string) {
        return this.http.post<User>(this.appParameter.loginUrl, {username, password})
            .pipe(map(user => this.setCurrentUser(user)));
    }

    setCurrentUser(user: User): User {
        if (user && user.accessToken) {
            // @ts-ignore
            sessionStorage.setItem('currentUser', JSON.stringify(user));

            this.currentUserSubject.next(user);
            this.currentUser = this.currentUserSubject.asObservable();
        }

        return user;
    }

    /**
     * Takes the current user and changes the access code/token accordingly
     * @param accessCode the access token to reassign to the user.
     */
    resetAccessCode(accessCode: string): User {
        if ((accessCode?.length ?? 0) === 0) {
            return null;
        }

        const user = this.currentUserSubject.value;
        user.accessToken = accessCode;
        return this.setCurrentUser(user);
    }

    getActiveCanvasUserId(): Observable<CanvasUserIdResponse> {
        if (!this.currentUserValue.isLti) {
            return of({
                canvasUserDimId: -1,
                canvasUserId: -1
            });
        }

        return new ApiHelper().listenOne<CanvasUserIdResponse>(this.http.get<ApiResult<CanvasUserIdResponse>>('/lti/user/active/id'));
    }

    azureGraphPhoto(): Observable<any> {
        const url = 'https://graph.microsoft.com/v1.0/me/photo/$value';
        return this.http.get(url, {responseType: 'blob'});
    }

    logout(redirectLogin: boolean, _ = true): Promise<boolean> | Observable<void> {
        if (!this.isAuthenticated) {
            return;
        }

        this.clearUser();

        if (redirectLogin === true) {
            return this.redirectToLogin();
        }

        return this.router.navigate(['/logoff']);
    }

    clearUser() {
        // @ts-ignore
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    getPhsuTools(): Observable<string[]> {
        if (this.phsuToolsSubject.value.length > 0) {
            return this.phsuToolsSubject;
        }

        const url = '/api/user/tool/list';
        return this.http.get<string[]>(url)
            .pipe(
                map(data => {
                    const result = data == null ? ['all'] : data;
                    this.phsuToolsSubject.next(result);

                    return result;
                })
            );
    }

    /**
     * Gets the user menu for the portal for the signed-in user
     */
    getUserMenu(): Observable<UserMenu> {
        const menuCache = this.userMenuSubject.value;
        if (menuCache == null || menuCache.memberId !== this.currentUserValue.id) {
            return this.getUserMenuApi();
        }

        return this.userMenuSubject.asObservable();
    }

    getParentMenu(route: string): Observable<MenuItem> {
        return this.getUserMenu()
            .pipe(
                map(data => data.menuItems.find(item => route.startsWith(item.routeMap)))
            );
    }

    getUserMenuApi(): Observable<UserMenu> {
        const url = '/api/user/menu';
        return new ApiHelper()
            .listenOne<UserMenu>(
                this.http.get<ApiResult<UserMenu>>(url)
            )
            .pipe(
                map(data => {
                    this.userMenuSubject.next(data);
                    return data;
                })
            );
    }

    findUserMenuItem(route: string[], parent: MenuItem): MenuItem {
        if (route == null || route.length === 0) {
            return null;
        }

        const mItem =
            (parent?.menuItems ||
                this.userMenuSubject.value?.menuItems ||
                [])
                .find(item => item.route === route[0]);

        return mItem != null && route.length > 1 ?
            this.findUserMenuItem(route.splice(1), mItem) || mItem :
            mItem;
    }

    firstMenuRoute(): string[] {
        const userMenu = this.userMenuSubject.value;
        if (userMenu == null ||
            (userMenu.menuItems || []).length === 0 ||
            (userMenu.menuItems[0].menuItems || []).length === 0
        ) {
            return ['404'];
        }

        return userMenu.menuItems[0].menuItems[0].routeMap.split('/').splice(1);
    }

    partnerSwitch(institutionCode: string): Observable<string> {
        const url = `/api/user/account/switch/${institutionCode}`;
        return new ApiHelper().listenOne(this.http.get<ApiResult<string>>(url))
            .pipe(
                tap(accessCode => {
                    this.resetAccessCode(accessCode);
                })
            );
    }

    protected redirectToLogin(): Promise<boolean> {
        return this.router.navigate(['login'], {queryParams: {returnUrl: this.router.url}});
    }
}
