/**
 * Checks to see if a link URL for a stylesheet is present in the header of the documents.
 * If the link is not found, the method adds it to the document header
 * @param url - The URL for the link (stylesheet)
 * @param createIsMissing - Add the stylesheet if missing (default true)
 */
export function checkAddStylesheet(url: string, createIsMissing = true): boolean {
    const links = document.head.getElementsByTagName('link');
    let stylesheetExists = links !== null &&  Array.from(links).some(item => item.href === url);

    if (!stylesheetExists && createIsMissing) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        document.head.appendChild(link);

        stylesheetExists = true;
    }

    return stylesheetExists;
}
