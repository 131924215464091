import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {CommonModule} from '@angular/common';

import {LoggedOffComponent} from './logged-off.component/logged-off.component';
import {TiberCommonControlsModule} from '../tiber-common-controls/tiber-common-controls.module';

@NgModule({
    declarations: [
        LoggedOffComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TiberCommonControlsModule
    ],
    exports: [
        LoggedOffComponent
    ]
})
export class TiberLoginModule {
}
