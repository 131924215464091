export function mergeObjects(source, target) {
    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            const sourceValue = source[key];
            const targetValue = target != null ? target[key] : undefined;

            if (sourceValue == null) {
                target[key] = sourceValue;
                return target;
            }

            if (Array.isArray(sourceValue)) {
                target[key] = [...(targetValue ?? []), ...sourceValue.map(item => mergeObjects(item, {}))];
            } else {
                switch (typeof sourceValue) {
                    case 'function':
                        target[key] = sourceValue.bind({});
                        break;
                    case 'object':
                        if (targetValue === undefined) {
                            target[key] = {};
                        }
                        mergeObjects(sourceValue, targetValue);

                    default:
                        target[key] = sourceValue;
                }
            }
        }
    }
    return target;
}
