import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PageService {

    showSidebar = true;
    sideBarLocked = true;
    lockable = true;
    pageTitleSubject = new Subject<string>();

    sideBarIsToggled = false;

    toggleSideBar = (forceValue?: boolean): boolean => {
        if (this.sideBarLocked) {
            return this.showSidebar;
        }

        this.showSidebar = forceValue || !this.showSidebar;

        if (this.showSidebar && !this.sideBarLocked) {
            // Allow the change detection to finish firing off before registering the click listener.
            setTimeout(() => window.addEventListener('click', this.handleClickEvent), 1);
        } else {
            window.removeEventListener('click', this.handleClickEvent);
        }

        return this.showSidebar;
    }

    toggleSideBarLock = (): boolean => {
        this.sideBarIsToggled = false;
        if (!this.lockable) {
            return this.sideBarLocked;
        }
        this.sideBarLocked = !this.sideBarLocked;

        if (this.sideBarLocked === false) {
            this.toggleSideBar();
            this.sideBarIsToggled = !this.sideBarIsToggled;
        }

        if (this.sideBarLocked) {
            window.removeEventListener('click', this.handleClickEvent);
        }
        return this.sideBarLocked;
    }

    smallWindowCheck(pixels: number) {
        const lockablePixels = 1441;
        this.lockable = pixels >= lockablePixels;

        if (!this.lockable && this.sideBarLocked) {
            this.sideBarLocked = false;
            this.toggleSideBar(false);
        }
    }

    private handleClickEvent = (event: Event) => {
        this.toggleSideBar();
    }
}
