export function getScrollParent(node: Element | null): HTMLElement  {
    if (!node || !(node instanceof Element)) {
        return null;
    }

    const overflowY = window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
    if (isScrollable && node.scrollHeight > node.clientHeight) {
        return node as HTMLElement;
    } else {
        return getScrollParent(node.parentNode as Element);
    }
}
