import {TiberEnvironmentProvider, Config, HeartbeatConfig} from ".";

export class DevelopmentEnvironment extends TiberEnvironmentProvider {
    config = {
        apiHost: "https://localhost:5001"
    } as Config;

    production = false;
    heartbeatConfig = {
        idleTimeout: 15,
        warningTimeout: 10
    } as HeartbeatConfig
}
