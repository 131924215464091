import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';

import {AuthenticationService} from '../../../../common/_services/authentication.service';
import {User} from '../../../../common/_models';

import {VERSION} from '../environments/version';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {EMPTY, Subscription} from 'rxjs';
import {PageService} from '../../../../common/_services/PageService';

import {MsalService} from "@azure/msal-angular";

@Component({
    selector: 'app-tiber-portal',
    templateUrl: './management-portal-app.component.html',
    styleUrls: ['./management-portal-app.component.scss']
})
export class ManagementPortalAppComponent implements OnInit, OnDestroy {
    currentUser: User;
    pageTitle: string;

    systemHealth = 'Retrieving';
    databaseName = 'Retrieving';
    environment = 'Retrieving';

    private titleSubscription: Subscription;

    get version(): string {
        return VERSION.version || 'localhost';
    }

    get copyrightYear(): number {
        return new Date().getUTCFullYear();
    }

    constructor(
        private readonly msalService: MsalService,
        private readonly authenticationService: AuthenticationService,
        private readonly httpClient: HttpClient,
        private readonly pageService: PageService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        this.authenticationService.currentUser?.subscribe(x => this.currentUser = x);
    }

    ngOnInit(): void {
        this.msalService.handleRedirectObservable().subscribe();
        this.httpClient.get('/healthcheck')
            .pipe(
                catchError(error => {
                    this.systemHealth = (error || {}).status || 'Error';
                    return EMPTY;
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.systemHealth = data.status;
                },
                error: error => {
                    this.systemHealth = (error || {}).status || 'Error';
                }}
            );

        this.httpClient.get('/api/system/v1/environment')
            .pipe(
                catchError(error => {
                    this.databaseName = (error || {}).status || 'Error';
                    this.environment = (error || {}).status || 'Error';
                    return EMPTY;
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.databaseName = data?.dbName ?? 'Unknown';
                    this.environment = data?.name ?? 'Unknown';
                },
                error: error => {
                    this.databaseName = (error || {}).status || 'Error';
                    this.environment = (error || {}).status || 'Error';
                }
            });

        this.titleSubscription = this.pageService.pageTitleSubject.subscribe(
            data => {
                this.pageTitle = data;
                this.changeDetectorRef.detectChanges();
            });
    }

    ngOnDestroy() {
        this.titleSubscription.unsubscribe();
    }
}
