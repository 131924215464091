import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {ManagementPortalAppComponent} from './management-portal-app.component';
import {MainComponent} from './main/main.component';
import {ErrorInterceptor, JwtInterceptor} from '../../../../common/_helpers';
import {TiberLoginModule} from '../../../../common/login/TiberLogin.module';
import {AppDetailsComponent} from './app-details.component/app-details.component';
import {AppEnvironment, environment} from '../environments/environment';
import {UrlSerializer} from '@angular/router';
import {UrlSerializerHelper} from '../../../../common/_helpers';
import {TiberCommonControlsModule} from '../../../../common/tiber-common-controls/tiber-common-controls.module';
import {
    APPLICATION_CONFIGURATION,
    ApplicationConfiguration
} from '../../../../common/_models/application-configuration';
import {AuthGaurd} from '../../../../common/_guards/Auth.guard';
import {MenuAuthGaurd} from '../../../../common/_guards/MenuAuthGaurd';
import {ManagementPortalInitializer} from '../../../../common/_services/management-portal-initializer';
import {ToastrModule} from 'ngx-toastr';

import {
    MsalModule,
    MsalService,
    MsalGuard,
    MsalInterceptor,
    MsalBroadcastService
} from "@azure/msal-angular";
import {AuthenticationMsalService} from "../../../../common/_services/authentication.msal.service";

import {PublicClientApplication, InteractionType, BrowserCacheLocation} from "@azure/msal-browser";
import {AuthenticationService} from "../../../../common/_services/authentication.service";
import {UrlHelpers} from '../../../../common/_helpers';
import {TiberEnvironmentProvider} from "../../../../common/environment";

@NgModule({
    declarations: [
        ManagementPortalAppComponent,
        MainComponent,
        AppDetailsComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
                auth: {
                    clientId: "6c6228d1-e86e-4fe5-8515-48f3ab30ee41",
                    authority: "https://login.microsoftonline.com/d9ed5dc1-b858-41d5-bfbf-db2554e2636e",
                    redirectUri: new UrlHelpers(environment).getRedirectUri(),
                    postLogoutRedirectUri: `${new UrlHelpers(environment).hostUri}/logoff`
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage,
                    storeAuthStateInCookie: true, // set to true for IE 11
                },
                system: {
                    loggerOptions: {
                        loggerCallback: () => {
                        },
                        piiLoggingEnabled: false,
                    }
                }
            }),
            {interactionType: InteractionType.Redirect}, // MSAL Guard Configuration
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new UrlHelpers(environment).resourceMap
            } // SAL Interceptor Configuration
        ),
        ToastrModule.forRoot(),
        TiberLoginModule,
        TiberCommonControlsModule,
        BrowserAnimationsModule,
    ],
    providers: [
        AuthenticationMsalService,
        {provide: TiberEnvironmentProvider, useClass: AppEnvironment},
        {
            provide: AuthenticationService,
            useExisting: AuthenticationMsalService
        },
        {
            provide: APP_INITIALIZER,
            useFactory: ManagementPortalInitializer,
            deps: [AuthenticationMsalService],
            multi: true
        },
        {
            provide: AuthGaurd,
            useClass: MenuAuthGaurd
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: ApplicationConfiguration,
            useValue: Object.assign(new ApplicationConfiguration(), environment[APPLICATION_CONFIGURATION] || {})
        },
        {
            provide: UrlSerializer,
            useClass: UrlSerializerHelper
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    bootstrap: [ManagementPortalAppComponent]
})
export class AppModule {
}
