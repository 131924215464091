import {Injectable} from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

import {AuthenticationService} from '../_services/authentication.service';
import {AuthGaurd} from './Auth.guard';

@Injectable({providedIn: 'root'})
export class MenuAuthGaurd implements CanActivate {

    constructor(
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService
    ) {
    }

    canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!new AuthGaurd(this.router, this.authenticationService).canActivate(router, state)) {
            return false;
        }

        let routeString = state.url;
        if (routeString == null || routeString.length === 0 || routeString === '/') {
            return true;
        }

        if (!routeString.startsWith('/')) {
            routeString = `/${routeString}`;
        }

        const routeParts = state.url.split('/').splice(1);
        const menuItem = this.authenticationService.findUserMenuItem(routeParts, null);

        if (menuItem != null && menuItem.appId >= 1000) {
            return true;
        }

        console.error(`Url: ${routeString} is invalid`);
        if (menuItem != null && (menuItem.menuItems || []).length > 0) {
            this.router.navigate(menuItem.menuItems[0].routeMap.split('/').splice(1));
        } else {
            this.router.navigate(this.authenticationService.firstMenuRoute());
        }

        return false;
    }
}
