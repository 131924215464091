/**
 * Converts the percentage to z-score on normalized curve
 * @param pct - percent to change to z-score
 */
export function normsInv(pct: number): number {
    const a = [-39.6968302866538, 220.946098424521, -275.928510446969, 138.357751867269, -30.6647980661472, 2.50662827745924];
    const b = [-54.4760987982241, 161.585836858041, -155.698979859887, 66.8013118877197, -13.2806815528857];
    const c = [-7.78489400243029E-03, -0.322396458041136, -2.40075827716184, -2.54973253934373, 4.37466414146497, 2.93816398269878];
    const d = [7.78469570904146E-03, 0.32246712907004, 2.445134137143, 3.75440866190742];
    const pctLow = 0.02425;
    const pctHigh = 1 - pctLow;
    let q;
    let r;
    let retVal: number;

    if ((pct < 0) || (pct > 1)) {
        alert('NormSInv: Argument out of range.');
        retVal = 0;
    } else if (pct < pctLow) {
        q = Math.sqrt(-2 * Math.log(pct));
        retVal = (((((c[0] * q + c[1]) * q + c[2]) * q + c[3]) * q + c[4]) * q + c[5]) / ((((d[0] * q + d[1]) * q + d[2]) * q + d[3]) * q + 1);
    } else if (pct <= pctHigh) {
        q = pct - 0.5;
        r = q * q;
        retVal = (((((a[0] * r + a[1]) * r + a[2]) * r + a[3]) * r + a[4]) * r + a[5]) * q / (((((b[0] * r + b[1]) * r + b[2]) * r + b[3]) * r + b[4]) * r + 1);
    } else {
        q = Math.sqrt(-2 * Math.log(1 - pct));
        retVal = -(((((c[0] * q + c[1]) * q + c[2]) * q + c[3]) * q + c[4]) * q + c[5]) / ((((d[0] * q + d[1]) * q + d[2]) * q + d[3]) * q + 1);
    }

    return retVal;
}
