import {Pipe, PipeTransform} from '@angular/core';
import {MenuItem} from '../_models/UserMenu';

@Pipe({
    name: 'visible'
})
export class VisibleMenuPipe implements PipeTransform {

    transform(items: MenuItem[]): MenuItem[] {
        return items.filter(item => item.showMenu);
    }
}
