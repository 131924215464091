import {Injectable} from "@angular/core";
import {AuthenticationService} from "./authentication.service";
import {HttpClient} from "@angular/common/http";
import {AppParameters} from "../_models/app.parameters";
import {Router} from "@angular/router";
import {MsalService} from "@azure/msal-angular";
import {Observable, of, Subscriber} from "rxjs";
import {User} from "../_models";
import {UrlHelpers} from "../_helpers";
import {map} from "rxjs/operators";

@Injectable()
export class AuthenticationMsalService extends AuthenticationService {
    constructor(http: HttpClient,
                appParameter: AppParameters,
                router: Router,
                private readonly urlHelpers: UrlHelpers,
                private readonly msalService: MsalService) {
        super(http, appParameter, router);
    }

    azureLogin = (): Observable<User> => new Observable<User>(observer => {
        const accounts = this.msalService.instance.getAllAccounts();
        if (accounts?.length > 0) {
            this.msalService.acquireTokenSilent({
                account: accounts[0],
                redirectUri: this.urlHelpers.getRedirectUri(),
                scopes: [
                    'user.read',
                    'api://6c6228d1-e86e-4fe5-8515-48f3ab30ee41/tiber-api'
                ]
            })
                .subscribe({
                    next: () => this.processGetTiberUser(observer),
                    error: error => observer.error(error)
                })
        } else {
            this.processGetAzureLogin(observer)
        }
    });

    azureLogout(): Observable<void> {
        if ((this.msalService.instance?.getAllAccounts()?.length ?? 0) <= 0) {
            return of(null);
        }

        return this.msalService.logout();
    }
    checkAzureConnect=(): boolean => (this.msalService.instance?.getAllAccounts()?.length ?? 0) > 0;

    msalLogin(): Observable<User> {
        const url = `${this.urlHelpers.apiHost}api/user/msal`;
        return this.http.get<User>(url)
            .pipe(
                map(user => this.setCurrentUser(user))
            );
    }

    logout(redirectLogin: boolean, azureLogout = true): Promise<boolean> | Observable<void> {
        if (azureLogout && this.checkAzureConnect()) {
            return this.azureLogout();
        }

        return super.logout(redirectLogin, azureLogout);
    }

    private processGetAzureLogin(observer: Subscriber<User>) {
        this.msalService.loginPopup()
            .subscribe({
                next: () => this.processGetTiberUser(observer),
                error: error => observer.error(error)
            });
    }
    private processGetTiberUser(observer: Subscriber<User>) {
        if (this.checkAzureConnect()) {
            this.msalLogin()
                .subscribe({
                    next: mData => observer.next(mData),
                    error: error => {
                        observer.error(error);
                    },
                    complete: () => observer.complete()
                });
        } else {
            observer.error(new Error('Azure Login Failed'));
        }
    }
}