import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map} from 'rxjs/operators';

@Injectable()
export class DataInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(map(data => {
                if (data instanceof HttpResponse) {
                    const newToken = data.headers.get('x-tiber-accesstoken');
                    if (newToken != null && (newToken ?? '').length > 0) {

                    }
                }
                return data;
            }));
    }
}
