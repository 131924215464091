import {Inject, Injectable} from "@angular/core";
import {TiberEnvironmentProvider} from "../environment";
import {firstOrDefault} from "./ArrayHelpers";

@Injectable({providedIn: 'root'})
export class UrlHelpers {

    constructor(@Inject(TiberEnvironmentProvider) private environment: TiberEnvironmentProvider) {
    }

    get apiHost(): string {
        let host = this.environment.config?.apiHost;
        if ((host || '').length === 0) {
            host = /^https:\/\/\S*?\//i.exec(document.location.href + '/')[0];
        }
        if (!(/.*\/$/.test(host))) {
            host += '/';
        }

        return host;
    }

    get hostUri(): string {
        return firstOrDefault(/^https:\/\/\S*?\//i.exec(document.location.href + '/')) ?? '/';
    }

    getRedirectUri(): string {
        let baseHref = document.querySelector('base')?.getAttribute('href');
        if ((baseHref?.length ?? 0) <= 1) {
            baseHref = '/';
        } else {
            baseHref = `${baseHref.substring(1)}`;
        }

        if (this.hostUri.endsWith('/') && baseHref.startsWith('/')) {
            baseHref = baseHref.length === 1 ? '' : baseHref.substring(1);
        }

        return `${this.hostUri}${baseHref}login`;
    }

    get resourceMap() {
        return new Map([
            ['https://graph.microsoft.com/v1.0/me', ['user.read']],
            [`${this.apiHost}api/user/msal`, ['api://6c6228d1-e86e-4fe5-8515-48f3ab30ee41/tiber-api']],
        ]);
    }

    checkMsalScoped(url: string): boolean {
        for (const key of this.resourceMap.keys()) {
            if (key.toLowerCase().startsWith(url.toLowerCase())) {
                return true;
            }
        }

        return false;
    }

}