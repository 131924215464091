import {EMPTY, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {firstOrDefault} from "./ArrayHelpers";

export interface ApiResult<TResult> {
    status: number;
    statusText: string;
    results: TResult[] | TResult;
    totalApiLoadTime: number;
    totalProcessingTime: number;
    message: string;
    exception: string;
}

export class ApiHelper {

    preListenFactory: () => void;

    catchError<TResult>(observable: Observable<ApiResult<TResult>>): Observable<ApiResult<TResult>> {
        return observable
            .pipe(
                map(item => item),
                catchError(err => {
                    alert(`API Transmission Error: ${err}`);
                    return EMPTY;
                }),
            );
    }

    preListen(factory: () => void): ApiHelper {
        this.preListenFactory = factory;

        return this;
    }


    listen<TResult>(
        observable: Observable<ApiResult<TResult>>,
        preCheck?: (result: TResult | TResult[], messages: string[]) => boolean
    ): Observable<TResult[]> {

        if (this.preListenFactory != null && typeof this.preListenFactory === 'function') {
            this.preListenFactory();
        }

        return this.catchError(observable)
            .pipe<TResult[]>(
                map(data => {
                    switch (data.status) {
                        case 1:
                            if (preCheck != null) {
                                const messages: string[] = [];
                                if (!preCheck(data.results, messages)) {
                                    throw new Error(`Validation Failed - ${messages.join('\n')}`);
                                }
                            }

                            switch (true) {
                                case (data == null || data.results == null):
                                    return null;
                                case Array.isArray(data.results):
                                    return data.results as TResult[];
                                default:
                                    return [data.results] as TResult[];
                            }
                            break;

                        default:
                            throw new Error(data.message);
                            break;
                    }
                }));
    }

    listenOne<TResult>(observable: Observable<ApiResult<TResult>>, preCheck?: (TResult) => boolean): Observable<TResult> {
        return this.listen(observable, preCheck)
            .pipe(
                map(data => {
                    if (Array.isArray(data)) {
                        if (data.length > 1) {
                            alert('More than 1 response received');
                            throw new Error('More than 1 response received');
                        }
                        return firstOrDefault(data);
                    } else {
                        return data;
                    }
                })
            );
    }
}
