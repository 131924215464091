// @ts-ignore
import {ApplicationConfiguration} from '../_models/application-configuration';

declare const __webpack_public_path__: string;

export function deployUrl(url: string, appConfig: ApplicationConfiguration): string {
    const publicPath = __webpack_public_path__;

    if (url.startsWith('/')) {
        url = url.slice(1);
    }

    if (url.toLowerCase().startsWith('assets/') && (appConfig.localUseCdn || (publicPath || '') !== '')) {
        return `${endSlash(appConfig.cdnPath)}${url}`;
    }

    return `${endSlash(publicPath)}${url}`;
}

export function endSlash(url: string) {
    return url.endsWith('/') ? url : `${url}/`;
}
