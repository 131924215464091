import {Injectable} from "@angular/core";
import {Config, HeartbeatConfig, TiberEnvironment} from ".";

@Injectable()
export class TiberEnvironmentProvider implements TiberEnvironment {
    production = false;
    syncfusionLicense = 'ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5ad01iXn5bdXFcRGBV';
    anychartLicense = 'tiberhealth.com-6710e107-2ec826cc';
    config = {
        apiHost: null,
        ltiTool: false
    } as Config;
    heartbeatConfig = {} as HeartbeatConfig;
};
