import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from '../_services/authentication.service';
import {RedirectPostService} from './RedirectPost.service';
import {TiberEnvironment, TiberEnvironmentProvider} from "../environment";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private readonly authenticationService: AuthenticationService,
        private redirectPostService: RedirectPostService,
        @Inject(TiberEnvironmentProvider) private environment: TiberEnvironment
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError(err => {
                if (err.status === 401 || err.status === 403) {
                    this.authenticationService.logout(false);

                    if (this.environment.config.ltiTool) {
                        this.redirectPostService.handleExceptionRedirect('Authentication Failed');
                    } else {
                        // @ts-ignore
                        location.reload();
                    }
                }

                const error = err.error || err.statusText;
                return throwError(error);
            }));
    }
}
