import {Injectable, Inject} from '@angular/core';
import {Router, CanActivate, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

import {AuthenticationService} from '../_services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGaurd implements CanActivate {

    constructor(
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService
    ) {
    }

    canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            return true; // Logged in
        }

        this.router.navigate(
            ['/login'],
            {queryParams: {returnUrl: state.url}});

        return false;
    }
}

