import { DatePipe } from '@angular/common';

export function sendFileToBrowser(data, reportName: string) {
    const datePipe = new DatePipe('en-US');
    const dateString = datePipe.transform(Date.now(), 'yyyyMMdd_HHmmss');
    downloadFile(data, `${reportName}_${dateString}.xlsx`);
}

export function downloadFile(data, reportFileName: string) {
    const reportBlob = new Blob([data], { type: 'application/octet-stream' });

    const reportDownloadLink = document.createElement('a');
    reportDownloadLink.href = URL.createObjectURL(reportBlob);
    reportDownloadLink.download = reportFileName;
    reportDownloadLink.click();
}
