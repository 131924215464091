export class QueryStringHelper {
    qetQueryStringEntries(wdw: Window): string[] {
        if (wdw == null) {
            return [];
        }
        return wdw?.location.search?.replace('?', '').split('&');
    }

    parseQueryParams(params: string[], map: Map<string, string | number | boolean>) {
        if (params == null || !Array.isArray(params) || params.length === 0) {
            return map;
        }

        for (const param of params) {
            const parts = param.split('=');
            const key = parts[0].trim().toLowerCase();
            if (key != null && key.length > 0) {
                const rawValue: string | number | boolean = parts.length > 1 && parts[1].length > 0 ? parts[1].trim() : true;
                const value = typeof (rawValue) === 'string'
                    ? ['true', 'false'].findIndex(item => item === rawValue.toLowerCase()) > 0
                        ? rawValue.toLowerCase() === 'true'
                        : rawValue
                    : rawValue;
                if (map.has(key)) {
                    map[key] = value;
                } else {
                    map.set(key, value);
                }
            }
        }

        return map;
    }

    getQueryParams(checkParent = false ): Map<string, string | number | boolean> {

        const returnMap = new Map<string, string | number | boolean>();
        if (checkParent) {
            this.parseQueryParams(this.qetQueryStringEntries(window.parent), returnMap);
        }
        this.parseQueryParams(this.qetQueryStringEntries(window), returnMap);

        return returnMap;
    }
}
