<div class="modal fade" role="dialog" [ngClass]="{'show app-details-show': show}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">System and User Information</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="vClient">Client Version:</label>
                                <input type="text" class="form-control" id="vClient" readonly [ngModel]="parent.version"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-9">
                            <div class="form-group">
                                <label>Membership:</label>
                                <input type="text" class="form-control" readonly [ngModel]="user.id"/>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="form-group">
                                <label>User:</label>
                                <input type="text" class="form-control" readonly [ngModel]="user.username"/>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Display Name:</label>
                                <input type="text" class="form-control" readonly [ngModel]="user.displayName"/>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Sortable Name:</label>
                                <input type="text" class="form-control" readonly [ngModel]="user.sortableName"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>API Key:</label>
                                <input type="text" class="form-control" readonly value="************************************************************"/>
                                <a style='float: right' href="javascript:void(0)" (click)="copyApiKey()" #apiCopy>Copy to Clipboard</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" (click)="hide()">Close</button>
            </div>
        </div>
    </div>
</div>
