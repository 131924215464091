import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {from, Observable, ObservableInput} from 'rxjs';
import {AuthenticationService} from '../_services/authentication.service';
import {map, mergeMap, tap} from 'rxjs/operators';
import {User} from '../_models';
import {UrlHelpers} from '.';
import {TiberEnvironmentProvider} from "../environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private readonly tiberAuthService: AuthenticationService,
        private readonly urlHelpers: UrlHelpers,
        @Inject(TiberEnvironmentProvider) private environment: TiberEnvironmentProvider
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.urlHelpers.checkMsalScoped(request.url)) {
            return next.handle(request);
        }

        const currentUser = this.tiberAuthService.currentUserValue;

        // substitute host if override is specified
        if (!request.url.toLowerCase().startsWith('https://')) {
            // @ts-ignore
            const host = this.environment.config.apiHost;
            if ((host || '').length > 0) {
                request = request.clone({
                    url: `${host}${request.url}`
                });
            }
        }

        return from(
            (currentUser && currentUser.accessToken) ?
                this.buildTiberToken(request, currentUser) :
                Promise.resolve(request)
        )
            .pipe(
                mergeMap(nextReq => next.handle(nextReq)),
                map(event => {
                    if (event instanceof HttpResponse) {
                        const token = event.headers.get('x-tiber-token');
                        if ((token ?? '').length > 0) {
                            this.tiberAuthService.resetAccessCode(token);
                        }
                    }
                    return event;
                })
            );
    }

    private buildTiberToken(request: HttpRequest<any>, currentUser: User): ObservableInput<any> {
        return Promise.resolve(request.clone({
            setHeaders: {
                Authorization: `Bearer ${currentUser.accessToken}`,
                'x-tiber-source': `tiber`
            }
        }));
    }

}
