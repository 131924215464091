import {AuthenticationService} from './authentication.service';

export function ManagementPortalInitializer(authService: AuthenticationService) {
    return () => {
        if (authService.isAuthenticated) {
            return authService.getUserMenu().toPromise();
        }
        return Promise.resolve();
    };
}
