export function sortArray<T>(array: T[], field: string, ascending: boolean) {
    return [...array].sort((a: {}, b: {}) => {
        const ascendValue = ascending ? -1 : 1;

        if (a[field] == null && b[field] == null) {
            return 0;
        }
        if (a[field] == null && b[field] != null) {
            return ascendValue;
        }
        if (a[field] != null && b[field] == null) {
            return -1 * ascendValue;
        }

        const aValue = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
        const bValue = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field];

        if (aValue < bValue) {
            return ascendValue;
        }
        if (aValue > bValue) {
            return -1 * ascendValue;
        }

        return 0;
    });
}
