import {trigger, transition, style, query, animate} from '@angular/animations';

export const fader =
    trigger('routeAnimations', [
        transition('* <=> *', [
            query(':enter', [
                style({
                    position: 'absolute',
                    left: 0,
                    width: '100%',
                    opactiv: 0
                })
            ]),
            query(':leave', [
                animate('600ms ease',
                    style({ opacity: 0 }))
            ], {optional: true}),
            query(':enter', [
                animate('600ms ease',
                    style({ opacity: 1 }))
            ])
        ])
    ]);
