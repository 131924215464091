import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ReportDropdownComponent} from './report-dropdown/report-dropdown.component';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {EnterEventDirective} from './enter-event.directive';
import {CdnImageDirective} from './cdn-image.directive/cdn-image.directive';
import {TiberVariableDirective} from './tiber-variable/tiber-variable.directive';
import {BlankComponent} from './blank.component';
import {VisibleMenuPipe} from './visible-menu-pipe';
import {TiberChartContainerComponent} from './tiber-chart-container.component/tiber-chart-container.component';


@NgModule({
    declarations: [
        BlankComponent,
        CdnImageDirective,
        EnterEventDirective,
        LoadingSpinnerComponent,
        ReportDropdownComponent,
        TiberChartContainerComponent,
        TiberVariableDirective,
        VisibleMenuPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        BlankComponent,
        CdnImageDirective,
        EnterEventDirective,
        LoadingSpinnerComponent,
        ReportDropdownComponent,
        TiberChartContainerComponent,
        TiberVariableDirective,
        VisibleMenuPipe
    ]
})
export class TiberCommonControlsModule {
}

