import {Component, Input, ViewChild} from '@angular/core';
import {ManagementPortalAppComponent} from '../management-portal-app.component';
import {AuthenticationService} from '../../../../../common/_services/authentication.service';
import {User} from '../../../../../common/_models';

@Component({
    selector: 'app-details',
    templateUrl: './app-details.component.html',
    styleUrls: [
        './app-details.component.scss'
    ]
})
export class AppDetailsComponent {
    @Input() show: boolean;

    @ViewChild('buttonElement') buttonElement: HTMLButtonElement;
    @ViewChild('apiCopy') apiCopy: any;

    user: User;

    constructor(
        public parent: ManagementPortalAppComponent,
        authService: AuthenticationService
    ) {
        this.user = authService.currentUserValue;
    }

    hide() {
        this.show = false;
    }

    copyApiKey() {
        navigator.clipboard.writeText(this.user.accessToken)
            .then(data => {
                alert('Copied');
            })
            .catch(error => {
                alert(`Failed to copy to clipboard: ${error}`);
            });
    }
}

