/**
 * Determines the y plot position for a Gaussian Distribution Curved
 * based on x, standard deviation and mean of the samples.
 * @param x The x plot for the curve
 * @param mean The mean of the data sample = 0 for normal distribution
 * @param std The standard deviation of the data sample - 1 for normal distribution
 */
export function gaussianValue(x: number, mean: number = 0, std: number = 1): number {
    const yExp = -1 * .5 * Math.pow(((x - mean) / std), 2);
    const ceff = 1 / (Math.sqrt(2 * Math.PI * Math.pow(std, 2)));
    const y = ceff * Math.pow(Math.E, yExp);

    return y;
}
